import React from 'react'
import Logo from '../Logo/Logo'
import Footer from '../Components/Footer/Footer'
import ContactForm from '../Components/Form/ContactForm'
import styles from './main.module.css'

const Main = () => {
  return (
    <div className='container'>
        <div style={{display: "flex", alignItems: "center"}}>
            <Logo />
            <h1 style={{marginLeft: 20}}>
                Cash378
            </h1>
        </div>
      <h1 className={styles.header}>Заполните заявку на подбор займа</h1>
      <ContactForm />
      <Footer />
    </div>
  )
}

export default Main
